import React from "react";
import { Container } from "react-bootstrap";
import {
  contentsTable, tiContent, title,
  description, img, contentTd,
  mainWrapper, contentCol
} from "./style.mod.scss";
import Link from "components/link";
import Image from "components/image";

const ContentsTable = ({ data }) => {
  if (!data || !data?.ContentsTableData) return <></>;

  return (
    <div className={contentsTable}>
      <Container className={mainWrapper}>
        <div className={tiContent}>
          <div className={contentCol}>
            <div className={contentTd}>
              <p className={title}>{ data.title }</p>
              <ol>
                {
                  data.ContentsTableData.map((_link) => (
                    <li key={_link._key}>
                      <Link to={`#${_link.sectionID}`} className={description}>{_link.title}</Link>
                    </li>
                  ))
                }
              </ol>
            </div>
          </div>
          <div className={contentCol}>
            <Image className={img} image={data.image}/>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ContentsTable;
